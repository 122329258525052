import { round } from './round';

export const toFixed = (number, decimals = 2) => {
	// Zaokruhlime cislo
	number = round(number, decimals);
	let negative = number < 0;

	// Pretypujeme cislo na string
	number = number.toString();

	// Najdeme pocet desatinnych miest
	const find_decimals = /\.(.*)/.exec(number);
	let d = '';

	if (find_decimals !== null && find_decimals.length === 2) {
		// Mame desatinne miesta
		d = find_decimals[1];
	}

	if (d.length !== decimals) {
		// Cislo nema tolko desatinnych miest akoby malo mat
		while (d.length < decimals) {
			d += '0';
		}
	}

	return `${negative ? '-' : ''}${Math.floor(Math.abs(number))}.${d}`;
};
