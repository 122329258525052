/**
 * Vratime percentualny narast medzi aktualnou a predoslou hodnotou.
 *
 * @param {float|int} actual
 * @param {float|int} before
 *
 * @returns {int}
 */
export const getGrowth = (actual, before) => {
	if (before === 0) {
		return 0;
	}

	return ((actual - before) / before) * 100;
};
