import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import EventIcon from '@mui/icons-material/Event';
import SettingsIcon from '@mui/icons-material/Settings';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PlaceIcon from '@mui/icons-material/Place';
import FlagIcon from '@mui/icons-material/Flag';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PeopleIcon from '@mui/icons-material/People';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GrassIcon from '@mui/icons-material/Grass';
import DescriptionIcon from '@mui/icons-material/Description';
import { getPermission } from 'src/functions/getPermission';

export const getMenuItems = () => {
	const permission = getPermission();

	return [
		{
			items: [
				{
					name: 'Kalendár',
					icon: EventIcon,
					link: '/app/calendar'
				},
				permission === 'admin'
					? {
							name: 'Dashboard',
							icon: SmartToyTwoToneIcon,
							link: '/app/dashboard'
					  }
					: null
			]
		},
		{
			heading: 'Akadémia',
			items: [
				{
					name: 'Tréningy',
					icon: SportsSoccerIcon,
					link: '/app/trainings'
				},
				permission === 'admin' || permission === 'coach'
					? {
							name: 'Hráči',
							icon: DirectionsRunIcon,
							link: '/app/players'
					  }
					: null,
				permission === 'admin'
					? {
							name: 'Tréneri',
							icon: AccessibilityIcon,
							link: '/app/coaches'
					  }
					: null,
				permission === 'admin' || permission === 'player'
					? {
							name: 'Faktúry',
							icon: DescriptionIcon,
							link: '/app/invoices'
					  }
					: null,
				permission === 'admin'
					? {
							name: 'Členovia',
							icon: PeopleIcon,
							link: '/app/users'
					  }
					: null,
				permission === 'admin'
					? {
							name: 'Miesta',
							icon: PlaceIcon,
							link: '/app/places'
					  }
					: null,
				permission === 'admin'
					? {
							name: 'Mestá',
							icon: LocationCityIcon,
							link: '/app/cities'
					  }
					: null,
				permission === 'admin'
					? {
							name: 'Plochy',
							icon: GrassIcon,
							link: '/app/areas'
					  }
					: null,
				permission === 'admin'
					? {
							name: 'Typy tréningov',
							icon: FormatListNumberedIcon,
							link: '/app/training-types'
					  }
					: null,
				permission === 'admin'
					? {
							name: 'Značky',
							icon: FlagIcon,
							link: '/app/flags'
					  }
					: null
			]
		},
		global.user.is_coach || global.user.is_player
			? {
					heading: 'Ostatné',
					items: [
						{
							name: 'Nastavenia',
							icon: SettingsIcon,
							link: `/app/${global.user.is_player ? 'players' : 'coaches'}/list/edit/${
								global.user.is_player ? global.user.player_id : global.user.coach_id
							}`
						}
					]
			  }
			: null
	];
};
