import axios from 'axios';
import _ from 'lodash';
import { getUrl } from './getUrl';

/**
 * GET/POST request.
 *
 * @param {string} uri
 * @param {object|boolean} data
 * @param {string} method
 * @param {object} params
 */
export const request = (uri, data = false, method = 'GET', params = {}) => {
	// Nasetujeme config pre axios
	const config = {
		method: method === 'FILE' ? 'POST' : method,
		url: `${getUrl()}${uri}`,
		headers: {
			'Accept': 'application/json',
			'Content-Type': method === 'FILE' ? 'multipart/form-data' : 'application/json; charset=UTF-8',
			'Authorization': !_.isUndefined(global.token) ? global.token : ''
		}
	};

	// Zoznam GET parametrov
	params.token = !_.isUndefined(global.token) ? global.token : '';

	switch (method) {
		case 'GET':
			// Pribalime GET parametre
			params = { ...params, ...data };
			break;

		case 'POST':
			// Mame form data ide o klasicky request
			config.data = JSON.stringify(data);
			break;

		case 'FILE':
			const fileData = new FormData();
			fileData.append('file', data);

			config.data = fileData;
			break;

		default:
			break;
	}

	params = _.join(
		_.map(_.keys(params), (key) => `${key}=${encodeURIComponent(params[key])}`),
		'&'
	);

	config.url = `${config.url}?${params}`;

	return axios(config);
};
