import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { request } from '../functions';

const initialAuthState = {
	isAuthenticated: false,
	isInitialized: false,
	user: null
};

global.user = null;

const setSession = (accessToken) => {
	if (accessToken) {
		localStorage.setItem('accessToken', accessToken);
		global.token = accessToken;
	} else {
		localStorage.removeItem('accessToken');
		global.token = '';
	}
};

const handlers = {
	INITIALIZE: (state, action) => {
		const { isAuthenticated, user } = action.payload;

		return {
			...state,
			isAuthenticated,
			isInitialized: true,
			user
		};
	},
	LOGIN: (state, action) => {
		const { user } = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user
		};
	},
	LOGOUT: (state) => ({
		...state,
		isAuthenticated: false,
		user: null
	}),
	REGISTER: (state, action) => {
		const { user } = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user
		};
	}
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
	...initialAuthState,
	method: 'JWT',
	login: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
	const { children } = props;
	const [state, dispatch] = useReducer(reducer, initialAuthState);

	useEffect(() => {
		const initialize = async () => {
			try {
				const accessToken = window.localStorage.getItem('accessToken');

				if (accessToken) {
					setSession(accessToken);

					let userData = null;

					await request('/users/session').then((response) => {
						if (_.has(response.data, 'id')) {
							userData = response.data;
						}
					});

					global.user = userData;

					dispatch({
						type: 'INITIALIZE',
						payload: {
							isAuthenticated: userData !== null,
							user: userData
						}
					});
				} else {
					dispatch({
						type: 'INITIALIZE',
						payload: {
							isAuthenticated: false,
							user: null
						}
					});
				}
			} catch (err) {
				dispatch({
					type: 'INITIALIZE',
					payload: {
						isAuthenticated: false,
						user: null
					}
				});
			}
		};

		initialize();
	}, []);

	const login = async (email, password) => {
		let userData = null;

		await request('/users/login', { email, password }, 'POST').then((response) => {
			if (_.has(response.data, 'id')) {
				userData = response.data;
			}
		});

		if (userData !== null) {
			global.user = userData;

			setSession(userData.token);
			dispatch({
				type: 'LOGIN',
				payload: {
					user: userData
				}
			});
		}
	};

	const logout = async () => {
		global.user = null;

		setSession(null);
		dispatch({ type: 'LOGOUT' });
	};

	const register = async (email, name, password) => {
		let userData = null;

		await request('/users/register', { name, email, password }, 'POST').then((response) => {
			if (_.has(response.data, 'id')) {
				userData = response.data;
			}
		});

		if (userData !== null) {
			global.user = userData;

			setSession(userData.token);
			dispatch({
				type: 'REGISTER',
				payload: {
					user: userData
				}
			});
		}
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'JWT',
				login,
				logout,
				register
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default AuthContext;
