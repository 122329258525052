import { FETCH_PLACES, CLEAN_PLACES, DELETE_PLACE, DELETE_PLACES } from '../actions/types';

const PLACES_DEFAULT_STATE = {};

const PlacesReducer = (state = PLACES_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_PLACES:
			return payload;

		case CLEAN_PLACES:
		case DELETE_PLACE:
		case DELETE_PLACES:
			return PLACES_DEFAULT_STATE;

		default:
			return state;
	}
};

export default PlacesReducer;
