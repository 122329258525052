import { mock } from '../utils/axios';

mock.onGet('/api/categories').reply(() => {
	return [
		200,
		{
			page: 1,
			per_page: 50,
			items: [
				{
					id: '1',
					parent_id: '1',
					user_source_ids: { '1a': '', '2b': '' },
					user_file_ids: ['10'],
					name: 'Brankářské rukavice',
					description:
						'Brankářské rukavice jsou nejdůležitější výbavou každého ochránce fotbalové svatyně. Právě zde naleznete kompletní seznam všech námi nabízených brankářských rukavic. Nabízíme rukavice, které používají profesionálové i amatéři po celém světě a v jakýchkoliv soutěžích.  Chceme, aby i nadšenci z regionálních lig chytali ve stejném vybavení, jako profíci, co hrají Evropskou ligu. Pro ty menší máme junior velikosti, kde využíváme Super Soft pěnu, ta má podobné vlastnosti, jako seniorská pěna Giga Grip. Avšak některé modely jsou úplně totožné s modely seniorskými, a tak využívají ty nejlepší materiály, jaké jsou na trhu dostupné. U senior modelů využíváme pěny Contact Grip a Giga Grip. Contact Grip, jak již název napovídá, se pyšní neskutečnou výdrží a zároveň má skvělou přilnavost. Giga Grip latex je sice měkčí, a tak možná nevydrží tak dlouho, avšak jeho vlastnosti jsou bezchybné za jakéhokoliv počasí. Nevíte jaké rukavice si vybrat? Mrkněte se na náš návod Jak si správně vybrat brankářské rukavice.',
					uri: 'kategorie/brankarske-rukavice',
					options: []
				}
			],
			count: 200,
			product_categories: {
				'1': {
					id: '1',
					name: 'Brankářské rukavice'
				}
			},
			user_files: {
				'10': {
					id: '10',
					url: 'https://api.bu1.cz/img/productcategory/2-m.webp?v=0.3592223209271257',
					alt: 'rukavice'
				}
			},
			user_sources: {
				'1a': {
					id: '1a',
					name: 'bu1.cz',
					color: '#FFA319'
				},
				'2b': {
					id: '2b',
					name: 'bu1sport.com',
					color: '#33C2FF'
				}
			},
			stats: {
				'1': {
					id: '1',
					products: 1234
				}
			}
		}
	];
});

mock.onGet('/api/categories/create').reply(() => {
	return [
		200,
		{
			sources: [
				{
					id: '1',
					name: 'bu1.cz',
					color: '#FFA319'
				},
				{
					id: '2',
					name: 'bu1sport.com',
					color: '#33C2FF'
				}
			]
		}
	];
});
