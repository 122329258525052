import { combineReducers } from '@reduxjs/toolkit';
import UserReducer from 'src/reducers/UserReducer';
import FlagsReducer from '../reducers/FlagsReducer';
import CitiesReducer from '../reducers/CitiesReducer';
import PlayersReducer from '../reducers/PlayersReducer';
import CoachesReducer from '../reducers/CoachesReducer';
import AreasReducer from '../reducers/AreasReducer';
import PlacesReducer from '../reducers/PlacesReducer';
import UsersReducer from '../reducers/UsersReducer';
import TrainingTypesReducer from '../reducers/TrainingTypesReducer';
import TrainingsReducer from '../reducers/TrainingsReducer';
import CalendarReducer from '../reducers/CalendarReducer';
import TrainingInvoicesReducer from '../reducers/TrainingInvoicesReducer';
import InvoicesReducer from '../reducers/InvoicesReducer';
import TrainingMultiInvoicesReducer from '../reducers/TrainingMultiInvoicesReducer';

const rootReducer = combineReducers({
	user: UserReducer,
	flags: FlagsReducer,
	cities: CitiesReducer,
	players: PlayersReducer,
	coaches: CoachesReducer,
	areas: AreasReducer,
	places: PlacesReducer,
	users: UsersReducer,
	training_types: TrainingTypesReducer,
	trainings: TrainingsReducer,
	calendar: CalendarReducer,
	training_invoices: TrainingInvoicesReducer,
	invoices: InvoicesReducer,
	training_multiinvoices: TrainingMultiInvoicesReducer,
});

export default rootReducer;
