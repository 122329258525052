import _ from 'lodash';

/**
 * Formatovanie vyrazu na cislo.
 *
 * @param {string|number|boolean} value
 *
 * @returns {number}
 */
export const toNumber = (value) => (_.isBoolean(value) ? (value ? 1 : 0) : _.toFinite(_.replace(_.toString(value), ',', '.')));
