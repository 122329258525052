import { FETCH_PLAYERS, CLEAN_PLAYERS, DELETE_PLAYER, DELETE_PLAYERS } from '../actions/types';

const PLAYERS_DEFAULT_STATE = {};

const PlayersReducer = (state = PLAYERS_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_PLAYERS:
			return payload;

		case CLEAN_PLAYERS:
		case DELETE_PLAYER:
		case DELETE_PLAYERS:
			return PLAYERS_DEFAULT_STATE;

		default:
			return state;
	}
};

export default PlayersReducer;
