const czJSON = {
	'Nepodarilo sa zmazať plochu': 'Nepodařilo se smazat plochu',
	'Plocha bola zmazaná': 'Plocha byla smazána',
	'Nepodarilo sa zmazať plochy': 'Nepodařilo se smazat plochy',
	'Plochy boli zmazané': 'Plochy byly smazány',
	'Nepodarilo sa zmazať mesto': 'Nepodařilo se smazat město',
	'Mesto bolo zmazané': 'Město bylo smazáno',
	'Nepodarilo sa zmazať mestá': 'Nepodařilo se smazat města',
	'Mestá boli zmazané': 'Města byla smazána',
	'Nepodarilo sa zmazať trénera': 'Nepodařilo se smazat trenéra',
	'Tréner bol zmazaný': 'Trenér byl smazán',
	'Nepodarilo sa zmazať trénerov': 'Nepodařilo se smazat trenéry',
	'Tréneri boli zmazaní': 'Trenéři byli smazáni',
	'Nepodarilo sa zmazať značku': 'Nepodařilo se smazat značku',
	'Značka bola zmazaná': 'Značka byla smazána',
	'Nepodarilo sa zmazať značky': 'Nepodařilo se smazat značky',
	'Značky boli zmazané': 'Značky byly smazány',
	'Nepodarilo sa zmazať faktúru': 'Nepodařilo se smazat fakturu',
	'Faktúra bola zmazaná': 'Faktura byla smazána',
	'Nepodarilo sa zmazať faktúry': 'Nepodařilo se smazat faktury',
	'Faktúry boli zmazané': 'Faktury byly smazány',
	'Nepodarilo sa zmazať miesto': 'Nepodařilo se smazat místo',
	'Miesto bolo zmazané': 'Místo bylo smazáno',
	'Nepodarilo sa zmazať miesta': 'Nepodařilo se smazat místa',
	'Miesta boli zmazané': 'Místa byla smazána',
	'Nepodarilo sa zmazať hráča': 'Nepodařilo se smazat hráče',
	'Hráč bol zmazaný': 'Hráč byl smazán',
	'Nepodarilo sa zmazať hráčov': 'Nepodařilo se smazat hráče',
	'Hráči boli zmazaní': 'Hráči byli smazáni',
	'Nepodarilo sa zmazať typ tréningu': 'Nepodařilo se smazat typ tréninku',
	'Typ tréningu bol zmazaný': 'Typ tréninku byl smazán',
	'Nepodarilo sa zmazať typy tréningu': 'Nepodařilo se smazat typy tréninku',
	'Typy tréningu boli zmazané': 'Typy tréninku byly smazány',
	'Nepodarilo sa zmazať tréning': 'Nepodařilo se smazat trénink',
	'Tréning bol zmazaný': 'Trénink byl smazán',
	'Nepodarilo sa zmazať tréningy': 'Nepodařilo se smazat tréninky',
	'Tréningy boli zmazané': 'Tréninky byly smazány',
	'Nepodarilo sa zmazať člena': 'Nepodařilo se smazat člena',
	'Člen bol zmazaný': 'Člen byl smazán',
	'Nepodarilo sa zmazať členov': 'Nepodařilo se smazat členy',
	'Členovia boli zmazaní': 'Členové byli smazáni',
	'oproti minulému mesiacu': 'oproti minulému měsíci',
	'Tréningy': 'Tréninky',
	'Typy tréningov': 'Typy tréninků',
	'Plochy': 'Plochy',
	'Mestá': 'Města',
	'Zobraziť všetky typy tréningov': 'Zobrazit všechny typy tréninků',
	'Tréneri': 'Trenéři',
	'Zobraziť': 'Zobrazit',
	'Zobraziť všetkých trénerov': 'Zobrazit všechny trenéry',
	'Tento rok': 'Letos',
	'Minulý rok': 'Minulý rok',
	'Hráči': 'Hráči',
	'Január': 'Leden',
	'Február': 'Únor',
	'Marec': 'Březen',
	'Apríl': 'Duben',
	'Máj': 'Květen',
	'Jún': 'Červen',
	'Júl': 'Červenec',
	'August': 'Srpen',
	'September': 'Září',
	'Október': 'Říjen',
	'November': 'Listopad',
	'December': 'Prosinec',
	'Tréningy a hráči': 'Tréninky a hráči',
	'Prehľady, štatistiky tréningov, hráčov a trénerov': 'Přehledy, statistiky tréninků, hráčů a trenérů',
	'Nadchádzajúce tréningy': 'Nadcházející tréninky',
	'Zobraziť všetky': 'Zobrazit vše',
	'BU1 akadémia - Dashboard': 'BU1 akademie - Dashboard',
	'Predošlý deň': 'Předchozí den',
	'Dnes': 'Dnes',
	'Ďalší deň': 'Další den',
	'Filtrovať': 'Filtrovat',
	'Zrušiť filter': 'Zrušit filtr',
	'Filtrovanie položiek': 'Filtrování položek',
	'Zadajte meno trénera...': 'Zadejte jméno trenéra...',
	'Vymazať': 'Vymazat',
	'Zavrieť': 'Zavřít',
	'Nenašli sme žiadne položky': 'Nenašli jsme žádné položky',
	'Miesta': 'Místa',
	'Zadajte názov miesta...': 'Zadejte název místa...',
	'Zadajte názov mesta...': 'Zadejte název města...',
	'Kalendár': 'Kalendář',
	'Zoznam tréningov': 'Seznam tréninků',
	'BU1 akadémia - kalendár': 'BU1 akademie - kalendář',
	'Názov plochy': 'Název plochy',
	'Zadajte názov plochy': 'Zadejte název plochy',
	'Uložiť plochu': 'Uložit plochu',
	'Úprava plochy': 'Úprava plochy',
	'Nová plocha': 'Nová plocha',
	'Ak chcete upraviť plochu vyplňte polia nižšie': 'Chcete-li upravit plochu vyplňte pole níže',
	'Ak chcete vytvoriť novú plochu, vyplňte polia nižšie': 'Chcete-li vytvořit novou plochu, vyplňte pole níže',
	'Späť na zoznam ploch': 'Zpět na seznam ploch',
	'Nepodarilo sa uložiť plochu': 'Nepodařilo se uložit plochu',
	'Plocha bola uložená': 'Plocha byla uložena',
	'BU1 akadémia - Úprava plochy': 'BU1 akademie - Úprava plochy',
	'BU1 akadémia - Nová plocha': 'BU1 akademie - Nová plocha',
	'označená položka': 'označená položka',
	'označené položky': 'označené položky',
	'označených položiek': 'označených položek',
	'Zmazať': 'Smazat',
	'Naozaj chcete zmazať označené plochy?': 'Naozaj chcete zmazať označené plochy?',
	'Po odstránení sa už dáta nebudú dať obnoviť': 'Po odstranění se již data nebudou dát obnovit',
	'Zrušiť': 'Zrušit',
	'Hľadať podľa názvu...': 'Hledat podle názvu...',
	'Žial nenašli sme žiadnu plochu': 'Žal nenašli jsme žádnou plochu',
	'Názov': 'Název',
	'Akcie': 'Akce',
	'Upraviť': 'Upravit',
	'z': 'z',
	'Počet na stránku': 'Počet na stránku',
	'Naozaj chcete zmazať túto plochu?': 'Opravdu chcete smazat tuto plochu?',
	'BU1 akadémia - plochy': 'BU1 akademie - plochy',
	'Názov mesta': 'Název města',
	'Zadajte názov mesta': 'Zadejte název města',
	'Uložiť mesto': 'Uložit město',
	'Úprava mesta': 'Úprava města',
	'Nové mesto': 'Nové město',
	'Ak chcete upraviť mesto vyplňte polia nižšie': 'Chcete-li upravit město vyplňte pole níže',
	'Ak chcete vytvoriť nové mesto, vyplňte polia nižšie': 'Chcete-li vytvořit nové město, vyplňte pole níže',
	'Späť na zoznam miest': 'Zpět na seznam míst',
	'Nepodarilo sa uložiť mesto': 'Nepodařilo se uložit město',
	'Mesto bolo uložené': 'Město bylo uloženo',
	'BU1 akadémia - Úprava mesta': 'BU1 akademie - Úprava města',
	'BU1 akadémia - Nové mesto': 'BU1 akademie - Nové město',
	'Naozaj chcete zmazať označené mestá?': 'Opravdu chcete smazat označená města?',
	'Žial nenašli sme žiadne mesto': 'Žal nenašli jsme žádné město',
	'Naozaj chcete zmazať toto mesto?': 'Opravdu chcete smazat toto město?',
	'BU1 akadémia - mestá': 'BU1 akademie - města',
	'Užívateľ': 'Uživatel',
	'Zadajte email člena...': 'Zadejte email člena...',
	'Zadajte člena': 'Zadejte člena',
	'Meno trénera': 'Jméno trenéra',
	'Meno': 'Jméno',
	'Zadajte meno trénera': 'Zadejte jméno trenéra',
	'Zadajte meno': 'Zadejte jméno',
	'Priezvisko trénera': 'Příjmení trenéra',
	'Priezvisko': 'Příjmení',
	'Zadajte priezvisko trénera': 'Zadejte příjmení trenéra',
	'Zadajte priezvisko': 'Zadejte příjmení',
	'Zadajte aspoň jedno mesto': 'Zadejte alespoň jedno město',
	'Adresa trénera': 'Adresa trenéra',
	'Adresa': 'Adresa',
	'Zadajte adresu trénera': 'Zadejte adresu trenéra',
	'Zadajte adresu': 'Zadejte adresu',
	'Email trénera': 'Email trenéra',
	'Email': 'Email',
	'Zadajte email trénera': 'Zadejte email trenéra',
	'Zadajte email': 'Zadejte email',
	'Tel. číslo trénera': 'Tel. číslo trenéra',
	'Tel. číslo': 'Tel. číslo',
	'Zadajte tel. číslo trénera': 'Zadejte tel. číslo trenéra',
	'Zadajte tel. číslo': 'Zadejte tel. číslo',
	'Dátum narodenia trénera': 'Datum narození trenéra',
	'Dátum narodenia': 'Datum narození',
	'Vyberte dátum': 'Vyberte datum',
	'Rodné číslo trénera': 'Rodné číslo trenéra',
	'Rodné číslo': 'Rodné číslo',
	'Veľkosť oblečenia': 'Velikost oblečení',
	'Veľkosť rukavic': 'Velikost rukavic',
	'Veľkosť nohy': 'Velikost nohy',
	'Bankový účet trénera': 'Bankovní účet trenéra',
	'Bankový účet': 'Bankovní účet',
	'Zadajte bankový účet trénera': 'Zadejte bankovní účet trenéra',
	'Zadajte bankový účet': 'Zadejte bankovní účet',
	'Značky': 'Značky',
	'Zadajte názov značky...': 'Zadejte název značky...',
	'Cena za tréningovú jednotku': 'Cena za tréninkovou jednotku',
	'Interná poznámka': 'Interní poznámka',
	'Uložiť trénera': 'Uložit trenéra',
	'Uložiť': 'Uložit',
	'Úprava trénera': 'Úprava trenéra',
	'Nastavenia': 'Nastavení',
	'Nový tréner': 'Nový trenér',
	'Ak chcete upraviť trénera, vyplňte polia nižšie': 'Chcete-li upravit trenéra, vyplňte pole níže',
	'Ak chcete upraviť nastavenia, vyplňte polia nižšie': 'Chcete-li upravit nastavení, vyplňte pole níže',
	'Ak chcete vytvoriť nového trénera, vyplňte polia nižšie': 'Chcete-li vytvořit nového trenéra, vyplňte pole níže',
	'Späť na zoznam trénerov': 'Zpět na seznam trenérů',
	'Súbor môže mať maximálne 5 MB.': 'Soubor může mít maximálně 5 MB.',
	'Fotografie trénera': 'Fotografie trenéra',
	'Fotografie': 'Fotografie',
	'Presuňte súbory sem alebo kliknite': 'Přetáhněte soubory sem nebo klikněte',
	'Tieto súbory nie sú povolené': 'Tyto soubory nejsou povoleny',
	'Nahrali ste': 'Nahráli jste',
	'súbor': 'soubor',
	'súbory': 'soubory',
	'súborov': 'souborů',
	'Naozaj chcete zmazať tento súbor?': 'Opravdu chcete smazat tento soubor?',
	'Nepodarilo sa uložiť trénera': 'Nepodařilo se uložit trenéra',
	'Nepodarilo sa uložiť nastavenia': 'Nepodařilo se uložit nastavení',
	'Tréner bol uložený': 'Trenér byl uložen',
	'Nastavenia boli uložené': 'Nastavení byla uložena',
	'Súbor sa nepodarilo uložiť': 'Soubor se nepodařilo uložit',
	'Súbor bol uložený': 'Soubor byl uložen',
	'BU1 akadémia - Úprava trénera': 'BU1 akademie - Úprava trenéra',
	'BU1 akadémia - Nastavenia': 'BU1 akademie - Nastavení',
	'BU1 akadémia - Nový tréner': 'BU1 akademie - Nový trenér',
	'Naozaj chcete zmazať označených trénerov?': 'Opravdu chcete smazat označené trenéry?',
	'Zadajte meno, email a pod...': 'Zadejte jméno, email a pod...',
	'Žial nenašli sme žiadneho trénera': 'Žal nenašli jsme žádného trenéra',
	'Tréner': 'Trenér',
	'Vytvoriť report': 'Vytvořit report',
	'Naozaj chcete zmazať tohto trénera?': 'Opravdu chcete smazat tohoto trenéra?',
	'BU1 akadémia - tréneri': 'BU1 akademie - trenéři',
	'Názov značky': 'Název značky',
	'Zadajte názov značky': 'Zadejte název značky',
	'Farba značky': 'Barva značky',
	'Zadajte farbu značky': 'Zadejte barvu značky',
	'Uložiť značku': 'Uložit značku',
	'Úprava značky': 'Úprava značky',
	'Nová značka': 'Nová značka',
	'Ak chcete upraviť značku, vyplňte polia nižšie': 'Chcete-li upravit značku, vyplňte pole níže',
	'Ak chcete vytvoriť novú značku, vyplňte polia nižšie': 'Chcete-li vytvořit novou značku, vyplňte pole níže',
	'Späť na zoznam značiek': 'Zpět na seznam značek',
	'Nepodarilo sa uložiť značku': 'Nepodařilo se uložit značku',
	'Značka bola uložená': 'Značka byla uložena',
	'BU1 akadémia - Úprava značky': 'BU1 akademie - Úprava značky',
	'BU1 akadémia - Nová značka': 'BU1 akademie - Nová značka',
	'Naozaj chcete zmazať označené značky?': 'Opravdu chcete smazat označené značky?',
	'Žial nenašli sme žiadnu značku': 'Žal nenašli jsme žádnou značku',
	'Značka': 'Značka',
	'Farba': 'Barva',
	'Naozaj chcete zmazať túto značku?': 'Opravdu chcete smazat tuto značku?',
	'BU1 akadémia - značky': 'BU1 akademie - značky',
	'Naozaj chcete zmazať označené faktúry?': 'Opravdu chcete smazat označené faktury?',
	'Faktúry': 'Faktury',
	'Zadajte číslo, VS..': 'Zadejte číslo, VS..',
	'Žial nenašli sme žiadnu faktúru': 'Žal nenašli jsme žádnou fakturu',
	'Faktúra': 'Faktura',
	'Stav': 'Stav',
	'Hráč': 'Hráč',
	'VS': 'VS',
	'Suma': 'Suma',
	'Uhradená suma': 'Uhrazená částka',
	'Čaká na úhradu': 'Čeká na úhradu',
	'Čiastočne uhradená': 'Částečně uhrazena',
	'Uhradená': 'Uhrazená',
	'Uhradiť': 'Uhradit',
	'Stiahnuť PDF': 'Stáhnout PDF',
	'Naozaj chcete zmazať túto faktúru?': 'Opravdu chcete smazat tuto fakturu?',
	'BU1 akadémia - faktúry': 'BU1 akademie - faktury',
	'BU1 akadémia - prihlásenie': 'BU1 akademie - přihlášení',
	'Prihlásenie': 'Přihlášení',
	'Vyplňte nasledujúce polia pre prihlásenie': 'Vyplňte následující pole pro přihlášení',
	'Nemáte ešte účet u nás?': 'Nemáte ještě účet u nás?',
	'Registrujte sa': 'Registrujte se',
	'Toto nie je email': 'Toto není email',
	'Prosím vyplňte email': 'Prosím vyplňte email',
	'Prosím vyplňte heslo': 'Prosím vyplňte heslo',
	'Musíte súhlasiť s VOP': 'Musíte souhlasit s VOP',
	'Heslo': 'Heslo',
	'Zabudli ste heslo?': 'Zapomněli jste heslo?',
	'Prihlásiť': 'Přihlásit',
	'BU1 akadémia - zabudnuté heslo': 'BU1 akademie - zapomenuté heslo',
	'Zabudnuté heslo': 'Zapomenuté heslo',
	'Zadajte svoj email pre resetovanie hesla.': 'Zadejte svůj email pro resetování hesla.',
	'Odoslať nové heslo': 'Odeslat nové heslo',
	'Chcete vyskúšať sa prihlásiť znovu?': 'Chcete vyzkoušet se přihlásit znovu?',
	'Kliknite sem': 'Klikněte sem',
	'Inštrukcie pre resetovanie hesla vám boli odoslané na email.': 'Instrukce pro resetování hesla vám byly odeslány na email.',
	'Skontrolujte svoj email pre ďalší postup.': 'Zkontrolujte svůj email pro další postup.',
	'Pokračovať na prihlásenie': 'Pokračovat na přihlášení',
	'BU1 akadémia - registrácia': 'BU1 akademie - registrace',
	'Registrácia': 'Registrace',
	'Vyplňte nasledujúce polia pre registráciu': 'Vyplňte následující pole pro registraci',
	'Už máte u nás účet': 'Už máte u nás účet',
	'Prihláste sa': 'Přihlaste se',
	'Prosím vyplňte meno': 'Prosím vyplňte jméno',
	'Heslo musí mať minimálne 8 znakov': 'Heslo musí mít minimálně 8 znaků',
	'Musíte súhlasit so všeobecnými obchodnými podmienkami': 'Musíte souhlasit s všeobecnými obchodními podmínkami',
	'Súhlasim so': 'Souhlasím se',
	'všeobecnými obchodnými podmienkami': 'všeobecnými obchodními podmínkami',
	'Registrovať': 'Registrovat',
	'Pracujeme na tom!': 'Pracujeme na tom!',
	'Táto stránke ešte nie je dostupná, ale pracujeme na tom :)': 'Tato stránka ještě není dostupná, ale pracujeme na tom :)',
	'Hups, stránka ktorú hľadáte neexistuje.': 'Hups, stránka kterou hledáte neexistuje.',
	'Naspäť na hlavnú stránku': 'Zpět na hlavní stránku',
	'Názov miesta': 'Název místa',
	'Začnite písať adresu': 'Začněte psát adresu',
	'Zadajte názov miesta': 'Zadejte název místa',
	'Mesto': 'Město',
	'Zadajte mesto': 'Zadejte město',
	'Plocha': 'Plocha',
	'Zadajte názov plochy...': 'Zadejte název plochy...',
	'Zadajte plochu': 'Zadejte plochu',
	'Uložiť miesto': 'Uložit místo',
	'Úprava miesta': 'Úprava místa',
	'Nové miesto': 'Nové místo',
	'Ak chcete upraviť miesto vyplňte polia nižšie': 'Chcete-li upravit místo vyplňte pole níže',
	'Ak chcete vytvoriť nové miesto, vyplňte polia nižšie': 'Chcete-li vytvořit nové místo, vyplňte pole níže',
	'Česko': 'Česko',
	'Slovensko': 'Slovensko',
	'Nepodarilo sa uložiť miesto': 'Nepodařilo se uložit místo',
	'Miesto bola uložené': 'Místo byla uložena',
	'BU1 akadémia - Úprava miesta': 'BU1 akademie - Úprava místa',
	'BU1 akadémia - Nové miesto': 'BU1 akademie - Nové místo',
	'Naozaj chcete zmazať označené miesta?': 'Opravdu chcete smazat označená místa?',
	'Žial nenašli sme žiadne miesto': 'Žal nenašli jsme žádné místo',
	'Naozaj chcete zmazať toto miesto?': 'Opravdu chcete smazat toto místo?',
	'BU1 akadémia - miesta': 'BU1 akademie - místa',
	'Meno hráča': 'Jméno hráče',
	'Zadajte meno hráča': 'Zadejte jméno hráče',
	'Priezvisko hráča': 'Příjmení hráče',
	'Zadajte priezvisko hráča': 'Zadejte příjmení hráče',
	'Email hráča': 'Email hráče',
	'Zadajte email hráča': 'Zadejte email hráče',
	'Tel. číslo hráča': 'Tel. číslo hráče',
	'Zadajte tel. číslo hráča': 'Zadejte tel. číslo hráče',
	'Dátum narodenia hráča': 'Datum narození hráče',
	'Rodné číslo hráča': 'Rodné číslo hráče',
	'Meno a priezvisko rodiča': 'Jméno a příjmení rodiče',
	'Email rodiča': 'Email rodiče',
	'Tel. číslo rodiča': 'Tel. číslo rodiče',
	'Email 2': 'Email 2',
	'Tel. číslo 2': 'Tel. číslo 2',
	'Uložiť hráča': 'Uložit hráče',
	'Úprava hráča': 'Úprava hráče',
	'Nový hráč': 'Nový hráč',
	'Ak chcete upraviť hráča, vyplňte polia nižšie': 'Chcete-li upravit hráče, vyplňte pole níže',
	'Ak chcete vytvoriť nového hráča, vyplňte polia nižšie': 'Chcete-li vytvořit nového hráče, vyplňte pole níže',
	'Späť na zoznam hráčov': 'Zpět na seznam hráčů',
	'Fotografie hráča': 'Fotografie hráče',
	'Nepodarilo sa uložiť hráča': 'Nepodařilo se uložit hráče',
	'Hráč bol uložený': 'Hráč byl uložen',
	'BU1 akadémia - Úprava hráča': 'BU1 akademie - Úprava hráče',
	'BU1 akadémia - Nový hráč': 'BU1 akademie - Nový hráč',
	'Naozaj chcete zmazať označených hráčov?': 'Opravdu chcete smazat označené hráče?',
	'Žial nenašli sme žiadneho hráča': 'Žal nenašli jsme žádného hráče',
	'Vystaviť faktúru': 'Vystavit fakturu',
	'Naozaj chcete zmazať tohto hráča?': 'Opravdu chcete smazat tohoto hráče?',
	'BU1 akadémia - hráči': 'BU1 akademie - hráči',
	'Názov typu tréningu': 'Název typu tréninku',
	'Zadajte názov typu tréningu': 'Zadejte název typu tréninku',
	'Farebné označenie tréningu': 'Barevné označení tréninku',
	'Zadajte farebné označenie tréningu': 'Zadejte barevné označení tréninku',
	'Max. počet hráčov tréningu': 'Max. počet hráčů tréninku',
	'Zadajte max. počet hráčov tréningu': 'Zadejte Max. počet hráčů tréninku',
	'Uložiť typ tréningu': 'Uložit typ tréninku',
	'Úprava typu tréningu': 'Úprava typu tréninku',
	'Nový typ tréningu': 'Nový typ tréninku',
	'Ak chcete upraviť typ tréningu vyplňte polia nižšie': 'Chcete-li upravit typ tréninku vyplňte pole níže',
	'Ak chcete vytvoriť nový typ tréningu, vyplňte polia nižšie': 'Chcete-li vytvořit nový typ tréninku, vyplňte pole níže',
	'Späť na zoznam typov tréningu': 'Zpět na seznam typů tréninku',
	'Nepodarilo sa uložiť typ tréningu': 'Nepodařilo se uložit typ tréninku',
	'Typ tréningu bol uložený': 'Typ tréninku byl uložen',
	'BU1 akadémia - Úprava typu tréningu': 'BU1 akademie - Úprava typu tréninku',
	'BU1 akadémia - Nový typ tréningu': 'BU1 akademie - Nový typ tréninku',
	'Naozaj chcete zmazať označené typy tréningu?': 'Opravdu chcete smazat označené typy tréninku?',
	'Žial nenašli sme žiadny typ tréningu': 'Žal nenašli jsme žádný typ tréninku',
	'Max. počet': 'Max. počet',
	'Naozaj chcete zmazať tento typ tréningu?': 'Opravdu chcete smazat tento typ tréninku?',
	'BU1 akadémia - typy tréningov': 'BU1 akademie - typy tréninků',
	'Dátum tréningu': 'Datum tréninku',
	'Zadajte dátum tréningu': 'Zadejte datum tréninku',
	'Čas tréningu': 'Čas tréninku',
	'Zadajte čas tréningu': 'Zadejte čas tréninku',
	'Vyberte čas': 'Vyberte čas',
	'Zadajte názov trénera...': 'Zadejte název trenéra...',
	'Zadajte trénera': 'Zadejte trenéra',
	'Typ tréningu': 'Typ tréninku',
	'Zadajte názov typu tréningu...': 'Zadejte název typu tréninku...',
	'Zadajte typ tréningu': 'Zadejte typ tréninku',
	'Miesto': 'Místo',
	'Zadajte miesto': 'Zadejte místo',
	'Zadajte meno hráča...': 'Zadejte jméno hráče...',
	'Zadajte aspoň jedného hráča': 'Zadejte alespoň jednoho hráče',
	'Uložiť tréning': 'Uložit trénink',
	'Úprava tréningu': 'Úprava tréninku',
	'Nový tréning': 'Nový trénink',
	'Ak chcete upraviť tréning, vyplňte polia nižšie': 'Chcete-li upravit trénink, vyplňte pole níže',
	'Ak chcete vytvoriť nový tréning, vyplňte polia nižšie': 'Chcete-li vytvořit nový trénink, vyplňte pole níže',
	'Späť na zoznam tréningov': 'Zpět na seznam tréninků',
	'Nepodarilo sa uložiť tréning': 'Nepodařilo se uložit trénink',
	'Tréning bol uložený': 'Trénink byl uložen',
	'BU1 akadémia - Úprava tréningu': 'BU1 akademie - Úprava tréninku',
	'BU1 akadémia - Nový tréning': 'BU1 akademie - Nový trénink',
	'Report tréningov': 'Report tréninků',
	'Fakturácia tréningov': 'Fakturace tréninků',
	'Pre vytvorenie reportu musíte označiť tréningy ktoré sa majú exportovať.': 'Pro vytvoření reportu musíte označit tréninky, které se mají exportovat.',
	'Pre vytvorenie faktúry musíte označiť tréningy ktoré sa majú fakturovať.': 'Pro vytvoření faktury musíte označit tréninky které mají být fakturovány.',
	'Vytvoriť faktúru': 'Vytvořit fakturu',
	'Tento týždeň': 'Tento týden',
	'Tento mesiac': 'Tento měsíc',
	'Minulý mesiac': 'Minulý měsíc',
	'Dátum od': 'Datum od',
	'Dátum do': 'Datum do',
	'Žial nenašli sme žiadny tréning': 'Žal nenašli jsme žádný trénink',
	'Tréning': 'Trénink',
	'Fakturovaná suma': 'Fakturovaná částka',
	'Nefakturované': 'Nefakturováno',
	'Vyfakturované': 'Vyfakturováno',
	'BU1 akadémia - fakturácia tréningov': 'BU1 akademie - fakturace tréninků',
	'Naozaj chcete zmazať označené tréningy?': 'Opravdu chcete smazat označené tréninky?',
	'Zadajte typ tréningu...': 'Zadejte typ tréninku...',
	'Naplánovaný': 'Naplánován',
	'Prebieha': 'Probíhá',
	'Dokončený': 'Dokončen',
	'Naozaj chcete zmazať tento tréning?': 'Opravdu chcete smazat tento trénink?',
	'BU1 akadémia - tréningy': 'BU1 akademie - tréninky',
	'Meno člena': 'Jméno člena',
	'Zadajte meno člena': 'Zadejte jméno člena',
	'Email člena': 'Email člena',
	'Zadajte email člena': 'Zadejte email člena',
	'Heslo člena': 'Heslo člena',
	'Zadajte heslo člena': 'Zadejte heslo člena',
	'Typ': 'Typ',
	'Uložiť člena': 'Uložit člena',
	'Úprava člena': 'Úprava člena',
	'Nový člen': 'Nový člen',
	'Ak chcete upraviť člena vyplňte polia nižšie': 'Chcete-li upravit člena vyplňte pole níže',
	'Ak chcete vytvoriť nového člena, vyplňte polia nižšie': 'Chcete-li vytvořit nového člena, vyplňte pole níže',
	'Späť na zoznam členov': 'Zpět na seznam členů',
	'Nepodarilo sa uložiť člena': 'Nepodařilo se uložit člena',
	'Člen bol uložený': 'Člen byl uložen',
	'BU1 akadémia - Úprava člena': 'BU1 akademie - Úprava člena',
	'BU1 akadémia - Nový člen': 'BU1 akademie - Nový člen',
	'Naozaj chcete zmazať označených členov?': 'Opravdu chcete smazat označené členy?',
	'Členovia': 'Členové',
	'Hľadať podľa mena, emailu...': 'Hledat podle jména, emailu...',
	'Žial nenašli sme žiadného člena': 'Žal nenašli jsme žádného člena',
	'Menu': 'Menu',
	'Admin': 'Admin',
	'Člen': 'Člen',
	'Naozaj chcete zmazať tohto člena?': 'Opravdu chcete smazat tohoto člena?',
	'BU1 akadémia - členovia': 'BU1 akademie - členové',
	'Výber jazyka': 'Výběr jazyka',
	'Slovensky': 'Česky',
	'Česky': 'Česky',
	'Vyhľadávanie...': 'Vyhledávání...',
	'Zadajte text...': 'Zadejte text...',
	'Začnite písať pre zobrazenie vyhľadávania...': 'Začněte psát pro zobrazení vyhledávání...',
	'Odhlásenie': 'Odhlášení',
	'Odoslať': 'Odeslat',
	'Odoslanie faktúry': 'Odeslání faktury',
	'Email sa nepodarilo odoslať': 'Email se nepodařilo odeslat',
	'Email bol odoslaný': 'Email byl odeslán'
};

export default czJSON;
