import { FETCH_TRAININGS, CLEAN_TRAININGS, DELETE_TRAINING, DELETE_TRAININGS } from '../actions/types';

const TRAININGS_DEFAULT_STATE = {};

const TrainingsReducer = (state = TRAININGS_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_TRAININGS:
			return payload;

		case CLEAN_TRAININGS:
		case DELETE_TRAINING:
		case DELETE_TRAININGS:
			return TRAININGS_DEFAULT_STATE;

		default:
			return state;
	}
};

export default TrainingsReducer;
