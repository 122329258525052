import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
	(
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);

const List = Loader(lazy(() => import('src/content/invoices/List')));

const invoicesRoutes = [
	{
		path: '',
		element: <Navigate to="list" replace />
	},
	{
		path: 'list',
		element: <List />
	}
];

export default invoicesRoutes;
