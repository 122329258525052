import { FETCH_INVOICES, CLEAN_INVOICES, DELETE_INVOICE, DELETE_INVOICES } from '../actions/types';

const INVOICES_DEFAULT_STATE = {};

const InvoicesReducer = (state = INVOICES_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_INVOICES:
			return payload;

		case CLEAN_INVOICES:
		case DELETE_INVOICE:
		case DELETE_INVOICES:
			return INVOICES_DEFAULT_STATE;

		default:
			return state;
	}
};

export default InvoicesReducer;
