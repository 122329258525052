import { FETCH_TRAINING_MULTI_INVOICES, CLEAN_TRAINING_MULTI_INVOICES } from '../actions/types';

const TRAINING_MULTI_INVOICES_DEFAULT_STATE = {};

const TrainingMultiInvoicesReducer = (state = TRAINING_MULTI_INVOICES_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_TRAINING_MULTI_INVOICES:
			return payload;

		case CLEAN_TRAINING_MULTI_INVOICES:
			return TRAINING_MULTI_INVOICES_DEFAULT_STATE;

		default:
			return state;
	}
};

export default TrainingMultiInvoicesReducer;
