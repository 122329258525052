import { useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import { Avatar, Box, Button, Divider, alpha, List, ListItem, ListItemText, Popover, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import _ from 'lodash';
import { getFileUrl } from '../../../../functions';

const UserBoxButton = styled(Button)(
	({ theme }) => `
    padding: ${theme.spacing(1)};
    background-color: ${alpha(theme.colors.alpha.black[100], 0.08)};

    .MuiButton-label {
      justify-content: flex-start;
    }

    &:hover {
      background-color: ${alpha(theme.colors.alpha.black[100], 0.12)};
    }
`
);

const MenuUserBox = styled(Box)(
	({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
	({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
	({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.sidebar.menuItemColor};
    display: block;

    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`
);

const UserBoxDescription = styled(Typography)(
	({ theme }) => `
    color: ${alpha(theme.sidebar.menuItemColor, 0.6)};

    &.popoverTypo {
      color: ${theme.palette.secondary.light};
    }
`
);

function SidebarTopSection() {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const location = useLocation();
	const { user, logout } = useAuth();

	const ref = useRef(null);
	const [isOpen, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleLogout = async () => {
		try {
			handleClose();
			await logout();
			navigate('/');
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<>
			<UserBoxButton fullWidth color="secondary" ref={ref} onClick={handleOpen}>
				<Avatar variant="rounded" alt={user.name} src={!_.isEmpty(user.avatar) ? getFileUrl(user.avatar) : ''} />
				<Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
					<UserBoxText>
						<UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
						<UserBoxDescription variant="body2">{`${user.type === 'admin' ? t('Admin') : t('Člen')}${user.is_player ? ' / ' + t('Hráč') : ''}${
							user.is_coach ? ' / ' + t('Tréner') : ''
						}`}</UserBoxDescription>
					</UserBoxText>
					<UnfoldMoreTwoToneIcon
						fontSize="small"
						sx={{
							ml: 1
						}}
					/>
				</Box>
			</UserBoxButton>
			<Popover
				disableScrollLock
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
			>
				<MenuUserBox
					sx={{
						minWidth: 210
					}}
					display="flex"
				>
					<Avatar variant="rounded" alt={user.name} src={!_.isEmpty(user.avatar) ? getFileUrl(user.avatar) : ''} />
					<UserBoxText>
						<UserBoxLabel className="popoverTypo" variant="body1">
							{user.name}
						</UserBoxLabel>
						<UserBoxDescription className="popoverTypo" variant="body2">
							{`${user.type === 'admin' ? t('Admin') : t('Člen')}${user.is_player ? ' / ' + t('Hráč') : ''}${user.is_coach ? ' / ' + t('Tréner') : ''}`}
						</UserBoxDescription>
					</UserBoxText>
				</MenuUserBox>
				<Divider
					sx={{
						mb: 0
					}}
				/>
				{user.is_player || user.is_coach ? (
					<List
						sx={{
							p: 1
						}}
						component="nav"
					>
						<ListItem
							onClick={() => {
								handleClose();
							}}
							button
							to={`/app/${user.is_player ? 'players' : 'coaches'}/list/edit/${user.is_player ? user.player_id : user.coach_id}`}
							component={NavLink}
						>
							<SettingsIcon fontSize="small" />
							<ListItemText primary={t('Nastavenia')} />
						</ListItem>
					</List>
				) : null}
				{user.is_player || user.is_coach ? <Divider /> : null}
				<Box m={1}>
					<Button color="primary" fullWidth onClick={handleLogout}>
						<LockOpenTwoToneIcon
							sx={{
								mr: 1
							}}
						/>
						{t('Odhlásenie')}
					</Button>
				</Box>
			</Popover>
		</>
	);
}

export default SidebarTopSection;
