// User
export const SET_USER = 'set_user';
export const CLEAN_USER = 'clean_user';
// Zakaznici flagy
export const FETCH_FLAGS = 'fetch_flags';
export const CLEAN_FLAGS = 'clean_flags';
export const DELETE_FLAG = 'delete_flag';
export const DELETE_FLAGS = 'delete_flags';
// Krajiny
export const FETCH_CITIES = 'fetch_cities';
export const CLEAN_CITIES = 'clean_cities';
export const DELETE_CITY = 'delete_city';
export const DELETE_CITIES = 'delete_cities';
// Hraci
export const FETCH_PLAYERS = 'fetch_players';
export const CLEAN_PLAYERS = 'clean_players';
export const DELETE_PLAYER = 'delete_player';
export const DELETE_PLAYERS = 'delete_players';
// Treneri
export const FETCH_COACHES = 'fetch_coaches';
export const CLEAN_COACHES = 'clean_coaches';
export const DELETE_COACH = 'delete_coach';
export const DELETE_COACHES = 'delete_coaches';
// Areas
export const FETCH_AREAS = 'fetch_areas';
export const CLEAN_AREAS = 'clean_areas';
export const DELETE_AREA = 'delete_area';
export const DELETE_AREAS = 'delete_areas';
// Places
export const FETCH_PLACES = 'fetch_places';
export const CLEAN_PLACES = 'clean_places';
export const DELETE_PLACE = 'delete_place';
export const DELETE_PLACES = 'delete_places';
// Users
export const FETCH_USERS = 'fetch_users';
export const CLEAN_USERS = 'clean_users';
export const DELETE_USER = 'delete_user';
export const DELETE_USERS = 'delete_users';
// Training types
export const FETCH_TRAINING_TYPES = 'fetch_training_types';
export const CLEAN_TRAINING_TYPES = 'clean_training_types';
export const DELETE_TRAINING_TYPE = 'delete_training_type';
export const DELETE_TRAINING_TYPES = 'delete_training_types';
// Trainings
export const FETCH_TRAININGS = 'fetch_trainings';
export const CLEAN_TRAININGS = 'clean_trainings';
export const DELETE_TRAINING = 'delete_training';
export const DELETE_TRAININGS = 'delete_trainings';
// Calendar
export const FETCH_CALENDAR = 'fetch_calendar';
export const CLEAN_CALENDAR = 'clean_calendar';
// Training invoices
export const FETCH_TRAINING_INVOICES = 'fetch_training_invoices';
export const CLEAN_TRAINING_INVOICES = 'clean_training_invoices';
// Training multi invoices
export const FETCH_TRAINING_MULTI_INVOICES = 'fetch_training_multi_invoices';
export const CLEAN_TRAINING_MULTI_INVOICES = 'clean_training_multi_invoices';
// Invoices
export const FETCH_INVOICES = 'fetch_invoices';
export const CLEAN_INVOICES = 'clean_invoices';
export const DELETE_INVOICE = 'delete_invoice';
export const DELETE_INVOICES = 'delete_invoices';
