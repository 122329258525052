import { FETCH_CALENDAR, CLEAN_CALENDAR } from '../actions/types';

const CALENDAR_DEFAULT_STATE = [];

const CalendarReducer = (state = CALENDAR_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_CALENDAR:
			return payload;

		case CLEAN_CALENDAR:
			return CALENDAR_DEFAULT_STATE;

		default:
			return state;
	}
};

export default CalendarReducer;
