import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';

const Loader = (Component) => (props) =>
	(
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);

const LoginCover = Loader(lazy(() => import('src/content/pages/Auth/Login/Cover')));
const RegisterCover = Loader(lazy(() => import('src/content/pages/Auth/Register/Cover')));
const RecoverPassword = Loader(lazy(() => import('src/content/pages/Auth/RecoverPassword')));

const accountRoutes = [
	{
		path: 'login',
		element: (
			<Guest>
				<LoginCover />
			</Guest>
		)
	},
	{
		path: 'register',
		element: (
			<Guest>
				<RegisterCover />
			</Guest>
		)
	},
	{
		path: 'recover-password',
		element: <RecoverPassword />
	}
];

export default accountRoutes;
