import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
	(
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);

const Calendar = Loader(lazy(() => import('src/content/applications/Calendar')));

const calendarRoutes = [
	{
		path: '',
		element: <Calendar />
	}
];

export default calendarRoutes;
