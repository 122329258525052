import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';
import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import accountRoutes from './account';
import baseRoutes from './base';
import dashboardRoutes from './dashboard';
import playersRoutes from './players';
import citiesRoutes from './cities';
import flagsRoutes from './flags';
import calendarRoutes from './calendar';
import settingsRoutes from './settings';
import statusRoutes from './status';
import coachesRoutes from './coaches';
import areasRoutes from './areas';
import placesRoutes from './places';
import usersRoutes from './users';
import trainingTypesRoutes from './training_types';
import trainingsRoutes from './trainings';
import invoicesRoutes from './invoices';

const router = [
	{
		path: 'account',
		children: accountRoutes
	},
	{
		path: '',
		element: <BaseLayout />,
		children: baseRoutes
	},
	{
		path: 'app',
		element: (
			<Authenticated>
				<AccentHeaderLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				element: <Navigate to="calendar" replace />
			},
			{
				path: 'status',
				children: statusRoutes
			},
			{
				path: 'dashboard',
				children: dashboardRoutes
			},
			{
				path: 'players',
				children: playersRoutes
			},
			{
				path: 'coaches',
				children: coachesRoutes
			},
			{
				path: 'cities',
				children: citiesRoutes
			},
			{
				path: 'users',
				children: usersRoutes
			},
			{
				path: 'places',
				children: placesRoutes
			},
			{
				path: 'areas',
				children: areasRoutes
			},
			{
				path: 'flags',
				children: flagsRoutes
			},
			{
				path: 'training-types',
				children: trainingTypesRoutes
			},
			{
				path: 'trainings',
				children: trainingsRoutes
			},
			{
				path: 'calendar',
				children: calendarRoutes
			},
			{
				path: 'invoices',
				children: invoicesRoutes
			},
			{
				path: 'settings',
				children: settingsRoutes
			}
		]
	}
];

export default router;
