import { FETCH_TRAINING_INVOICES, CLEAN_TRAINING_INVOICES } from '../actions/types';

const TRAINING_INVOICES_DEFAULT_STATE = {};

const TrainingInvoicesReducer = (state = TRAINING_INVOICES_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_TRAINING_INVOICES:
			return payload;

		case CLEAN_TRAINING_INVOICES:
			return TRAINING_INVOICES_DEFAULT_STATE;

		default:
			return state;
	}
};

export default TrainingInvoicesReducer;
