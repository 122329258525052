import { FETCH_FLAGS, CLEAN_FLAGS, DELETE_FLAG, DELETE_FLAGS } from '../actions/types';

const FLAGS_DEFAULT_STATE = {};

const FlagsReducer = (state = FLAGS_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_FLAGS:
			return payload;

		case CLEAN_FLAGS:
		case DELETE_FLAG:
		case DELETE_FLAGS:
			return FLAGS_DEFAULT_STATE;

		default:
			return state;
	}
};

export default FlagsReducer;
