import { FETCH_USERS, CLEAN_USERS, DELETE_USER, DELETE_USERS } from '../actions/types';

const USERS_DEFAULT_STATE = {};

const UsersReducer = (state = USERS_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_USERS:
			return payload;

		case CLEAN_USERS:
		case DELETE_USER:
		case DELETE_USERS:
			return USERS_DEFAULT_STATE;

		default:
			return state;
	}
};

export default UsersReducer;
