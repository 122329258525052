import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import skJSON from './translations/sk';
import czJSON from './translations/cz';

const resources = {
	cz: { translation: czJSON },
	sk: { translation: skJSON }
};

i18n.use(initReactI18next).init({
	resources,
	keySeparator: false,
	lng: 'cz',
	fallbackLng: 'cz',
	react: {
		useSuspense: true
	},
	interpolation: {
		escapeValue: false
	}
});

export default i18n;
