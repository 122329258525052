import { Box } from '@mui/material';
import LanguageSwitcher from './LanguageSwitcher';

function HeaderButtons() {
	return (
		<Box
			sx={{
				mr: 1
			}}
		>
			<LanguageSwitcher />
		</Box>
	);
}

export default HeaderButtons;
