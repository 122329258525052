import { FETCH_COACHES, CLEAN_COACHES, DELETE_COACH, DELETE_COACHES } from '../actions/types';

const COACHES_DEFAULT_STATE = {};

const CoachesReducer = (state = COACHES_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_COACHES:
			return payload;

		case CLEAN_COACHES:
		case DELETE_COACH:
		case DELETE_COACHES:
			return COACHES_DEFAULT_STATE;

		default:
			return state;
	}
};

export default CoachesReducer;
