import { Box, Tooltip, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
	({ theme }) => `
        padding: ${theme.spacing(0, 1, 0, 0)};
        text-decoration: none;
        display: flex;
`
);

const LogoTextWhite = styled(Box)(
	() => `
	    color: white;
`
);

const LogoContainer = styled(Box)(
	() => `
        font-family: 'Ubuntu', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: bold;
        color: #0d2555;
        display: flex;
        flex-direction: row;
        align-items: center;
`
);

function Logo() {
	return (
		<LogoWrapper to="/app">
			<img src="/static/images/logo.png" alt="logo" style={{ height: '30px' }} />
		</LogoWrapper>
	);
}

export default Logo;
