import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
	(
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);

const Dashboard = Loader(lazy(() => import('src/content/Dashboard')));

const dashboardRoutes = [
	{
		path: '',
		element: <Dashboard />
	}
];

export default dashboardRoutes;
