import { useRef, useState } from 'react';

import { IconButton, Box, List, alpha, ListItem, Typography, ListItemText, Popover, Tooltip, styled } from '@mui/material';

import internationalization from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';

import { CZ } from 'country-flag-icons/react/3x2';
import { SK } from 'country-flag-icons/react/3x2';

const SectionHeading = styled(Typography)(
	({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);

const IconButtonPrimary = styled(IconButton)(
	({ theme }) => `
    margin-left: ${theme.spacing(1)};
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    svg {
      width: 26px;
    }

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

function LanguageSwitcher() {
	const { i18n } = useTranslation();
	const { t } = useTranslation();
	const getLanguage = i18n.language;

	const switchLanguage = ({ lng }) => {
		internationalization.changeLanguage(lng);
	};
	const ref = useRef(null);
	const [isOpen, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Tooltip arrow title={t('Výber jazyka')}>
				<IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
					{getLanguage === 'sk' && <SK title={t('Slovensky')} />}
					{getLanguage === 'cz' && <CZ title={t('Česky')} />}
				</IconButtonPrimary>
			</Tooltip>
			<Popover
				disableScrollLock
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<Box
					sx={{
						maxWidth: 240
					}}
				>
					<SectionHeading variant="body2" color="text.primary">
						{t('Výber jazyka')}
					</SectionHeading>
					<List
						sx={{
							p: 2,
							svg: {
								width: 26,
								mr: 1
							}
						}}
						component="nav"
					>
						<ListItem
							className={getLanguage === 'cz' ? 'active' : ''}
							button
							onClick={() => {
								switchLanguage({ lng: 'cz' });
								handleClose();
							}}
						>
							<CZ title={t('Česky')} />
							<ListItemText
								sx={{
									pl: 1
								}}
								primary={t('Česky')}
							/>
						</ListItem>
						<ListItem
							className={getLanguage === 'sk' ? 'active' : ''}
							button
							onClick={() => {
								switchLanguage({ lng: 'sk' });
								handleClose();
							}}
						>
							<SK title={t('Slovensky')} />
							<ListItemText
								sx={{
									pl: 1
								}}
								primary={t('Slovensky')}
							/>
						</ListItem>
					</List>
				</Box>
			</Popover>
		</>
	);
}

export default LanguageSwitcher;
