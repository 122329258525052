export const TINY_HMTL_API_KEY = '0bu83brr546ewloep2ahpqamxdfdukdssi5xzxynxwhewmgu';
export const DEVELOPMENT_URL = 'http://akademie.loc:80';
export const PRODUCTION_URL = 'https://api-akademia.goodeshop.sk';
export const CURRENCIES = {
	EUR: '€',
	CZK: 'Kč',
	HUF: 'Ft',
	USD: '$',
	GBP: '£'
};
