import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
	(
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);

const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));

const statusRoutes = [
	{
		path: '',
		element: <StatusComingSoon />
	}
];

export default statusRoutes;
