import { FETCH_CITIES, CLEAN_CITIES, DELETE_CITY, DELETE_CITIES } from '../actions/types';

const CITIES_DEFAULT_STATE = {};

const CitiesReducer = (state = CITIES_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_CITIES:
			return payload;

		case CLEAN_CITIES:
		case DELETE_CITY:
		case DELETE_CITIES:
			return CITIES_DEFAULT_STATE;

		default:
			return state;
	}
};

export default CitiesReducer;
