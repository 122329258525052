export const getPermission = () => {
	if (_.isEmpty(global.user)) {
		return 'user';
	}

	if (global.user.type === 'admin') {
		return 'admin';
	}

	if (global.user.is_coach) {
		return 'coach';
	}

	if (global.user.is_player) {
		return 'player';
	}

	return 'user';
};
