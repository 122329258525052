import { FETCH_AREAS, CLEAN_AREAS, DELETE_AREA, DELETE_AREAS } from '../actions/types';

const AREAS_DEFAULT_STATE = {};

const AreasReducer = (state = AREAS_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_AREAS:
			return payload;

		case CLEAN_AREAS:
		case DELETE_AREA:
		case DELETE_AREAS:
			return AREAS_DEFAULT_STATE;

		default:
			return state;
	}
};

export default AreasReducer;
