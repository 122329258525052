import { Fragment, forwardRef, Component } from 'react';
import { Box, Divider, Button, CircularProgress, InputBase, Typography, Card, Dialog, alpha, Slide, styled, useTheme } from '@mui/material';
import { withTheme } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import Scrollbar from 'src/components/Scrollbar';
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import { getPermission, request } from 'src/functions';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
	() => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(InputBase)(
	({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(2)};
    width: 100%;
`
);

const ListButton = styled(Box)(
	({ theme }) => `
      background-color: transparent;
      color:  ${theme.colors.alpha.black[100]};
      transition: ${theme.transitions.create(['all'])};
      border: ${theme.colors.alpha.black[10]} solid 1px;
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(1)};
      margin: ${theme.spacing(1, 0)};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div > .MuiSvgIcon-root {
        color:  ${theme.colors.alpha.black[50]};
        transition: ${theme.transitions.create(['all'])};
      }

      &:hover {
        background-color: ${alpha(theme.colors.primary.main, 0.08)};
        color:  ${theme.colors.primary.main};
        border-color: ${alpha(theme.colors.primary.main, 0.3)};

        & > div > .MuiSvgIcon-root {
          color:  ${theme.colors.primary.main};
        }
      }
`
);

const SearchInputWrapperButton = styled(Button)(
	({ theme }) => `
  font-weight: normal;
  background: ${theme.colors.alpha.white[10]};
  border:  ${alpha(theme.colors.alpha.white[30], 0.2)} solid 1px;

  &:hover {
    background: ${alpha(theme.colors.alpha.white[30], 0.15)};
    border: ${theme.colors.alpha.white[30]} solid 1px;
  }
`
);

const BtnControl = styled(Box)(
	({ theme }) => `
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px !important;
  background: ${alpha(theme.colors.alpha.white[100], 0.8)};
  color: ${theme.colors.alpha.black[100]};
  border-radius: ${theme.general.borderRadiusSm};
  padding: 0 6px;
  margin-left: ${theme.spacing(2)};
`
);

const searchTerms = {
	Dashboards: [
		{
			title: 'Automation UI'
		},
		{
			title: 'Banking Performance'
		},
		{
			title: 'Fitness Statistics'
		}
	],
	Applications: [
		{
			title: 'Events Manager'
		},
		{
			title: 'Messenging Platform'
		}
	],
	Management: [
		{
			title: 'Products Admin'
		},
		{
			title: 'Customers Database'
		},
		{
			title: 'Learning Center'
		},
		{
			title: 'Invoices Archive'
		}
	]
};

class HeaderSearch extends Component {
	constructor(props) {
		super(props);

		this.searchTimeout = null;
		this.state = {
			open: false,
			searchValue: '',
			searchLoading: false,
			searchResults: false
		};
	}

	search(query) {
		if (query.length >= 3) {
			if (this.searchTimeout !== null) {
				clearTimeout(this.searchTimeout);
			}

			this.setState({ searchValue: query, searchLoading: true });

			this.searchTimeout = setTimeout(() => {
				request('/users/searchAll', { query }).then((response) => {
					const { status, data } = response.data;

					if (status === 'success') {
						this.setState({ searchLoading: false, searchResults: data });
					}
				});
			}, 500);
			return;
		}

		this.setState({ searchValue: query, searchLoading: false, searchResults: false });
	}

	render() {
		if (getPermission() !== 'admin') {
			return null;
		}

		const { t, theme } = this.props;
		const { open, searchValue, searchLoading, searchResults } = this.state;

		return (
			<>
				<SearchInputWrapperButton
					variant="contained"
					disableElevation
					onClick={() => this.setState({ open: true })}
					startIcon={<SearchTwoToneIcon />}
					endIcon={<BtnControl>Shift+S</BtnControl>}
				>
					{t('Vyhľadávanie...')}
				</SearchInputWrapperButton>

				<DialogWrapper
					open={open}
					TransitionComponent={Transition}
					keepMounted
					maxWidth="sm"
					fullWidth
					scroll="paper"
					onClose={() => this.setState({ open: false })}
				>
					<Box>
						<form>
							<Box display="flex" alignItems="center">
								<Box flexGrow={1} display="flex" alignItems="center">
									<SearchTwoToneIcon
										sx={{
											ml: 2,
											color: theme.colors.secondary.main
										}}
									/>
									<SearchInputWrapper
										value={searchValue}
										onChange={(event) => this.search(event.target.value)}
										autoFocus
										placeholder={t('Zadajte text...')}
										fullWidth
									/>
								</Box>
								<Card
									sx={{
										ml: 'auto',
										mr: 2,
										py: 0.5,
										px: 1,
										background: theme.colors.alpha.black[10]
									}}
								>
									<Typography variant="body2" color="text.secondary" fontWeight="bold">
										esc
									</Typography>
								</Card>
							</Box>
						</form>
					</Box>
					<Divider />
					{!searchLoading && (
						<>
							{!searchResults && (
								<Typography
									variant="subtitle1"
									component="div"
									sx={{
										background: theme.colors.info.lighter,
										color: theme.colors.info.main,
										borderRadius: theme.general.borderRadius,
										fontSize: theme.typography.pxToRem(13),
										display: 'flex',
										alignItems: 'center',
										p: 1,
										mx: 2,
										my: 2
									}}
								>
									<ContactSupportTwoToneIcon
										sx={{
											mr: 0.8,
											fontSize: theme.typography.pxToRem(18)
										}}
									/>
									{t('Začnite písať pre zobrazenie vyhľadávania...')}
								</Typography>
							)}
						</>
					)}
					{searchLoading ? (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								my: 5
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<>
							<Box
								sx={{
									height: 450
								}}
							>
								<Scrollbar>
									{_.map(searchResults, (items, type) => {
										if (_.isEmpty(items)) {
											return null;
										}

										let name = type;
										let redirect = '';

										switch (type) {
											case 'areas':
												name = t('Plochy');
												redirect = '/app/areas/list/edit';
												break;

											case 'cities':
												name = t('Mestá');
												redirect = '/app/cities/list/edit';
												break;

											case 'coaches':
												name = t('Tréneri');
												redirect = '/app/coaches/list/edit';
												break;

											case 'flags':
												name = t('Značky');
												redirect = '/app/flags/list/edit';
												break;

											case 'invoices':
												name = t('Faktúry');
												redirect = '/app/invoices/list/edit';
												break;

											case 'places':
												name = t('Miesta');
												redirect = '/app/places/list/edit';
												break;

											case 'players':
												name = t('Hráči');
												redirect = '/app/players/list/edit';
												break;

											case 'training_types':
												name = t('Typy tréningov');
												redirect = '/app/training-types/list/edit';
												break;

											case 'users':
												name = t('Členovia');
												redirect = '/app/users/list/edit';
												break;

											default:
												break;
										}

										return (
											<Box px={2} py={1} key={type}>
												<Typography
													sx={{
														py: 1
													}}
													variant="h5"
												>
													{name}
												</Typography>
												{_.map(items, ({ id, name }) => (
													<Fragment key={id}>
														<ListButton onClick={() => (window.location = `${redirect}/${id}`)}>
															<Box display="flex" alignItems="flex-start">
																<RestoreTwoToneIcon
																	sx={{
																		mr: 1
																	}}
																	fontSize="small"
																/>
																<Typography>{name}</Typography>
															</Box>
															<KeyboardArrowRightTwoToneIcon fontSize="small" />
														</ListButton>
													</Fragment>
												))}
											</Box>
										);
									})}
								</Scrollbar>
							</Box>
						</>
					)}
				</DialogWrapper>
			</>
		);
	}
}

export default withTheme(withTranslation()(HeaderSearch));
