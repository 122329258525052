import { FETCH_TRAINING_TYPES, CLEAN_TRAINING_TYPES, DELETE_TRAINING_TYPE, DELETE_TRAINING_TYPES } from '../actions/types';

const TRAINING_TYPES_DEFAULT_STATE = {};

const TrainingTypesReducer = (state = TRAINING_TYPES_DEFAULT_STATE, { type, payload }) => {
	switch (type) {
		case FETCH_TRAINING_TYPES:
			return payload;

		case CLEAN_TRAINING_TYPES:
		case DELETE_TRAINING_TYPE:
		case DELETE_TRAINING_TYPES:
			return TRAINING_TYPES_DEFAULT_STATE;

		default:
			return state;
	}
};

export default TrainingTypesReducer;
