import * as Yup from 'yup';

import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, FormHelperText, TextField, Checkbox, Typography, Link, FormControlLabel, CircularProgress } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';

const LoginJWT = () => {
	const { login } = useAuth();
	const isMountedRef = useRefMounted();
	const { t } = useTranslation();

	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
				terms: true,
				submit: null
			}}
			validationSchema={Yup.object().shape({
				email: Yup.string().email(t('Toto nie je email')).max(255).required(t('Prosím vyplňte email')),
				password: Yup.string().max(255).required(t('Prosím vyplňte heslo')),
				terms: Yup.boolean().oneOf([true], t('Musíte súhlasiť s VOP'))
			})}
			onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
				try {
					await login(values.email, values.password);

					if (isMountedRef.current) {
						setStatus({ success: true });
						setSubmitting(false);
					}
				} catch (err) {
					console.error(err);
					if (isMountedRef.current) {
						setStatus({ success: false });
						setErrors({ submit: err.message });
						setSubmitting(false);
					}
				}
			}}
		>
			{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
				<form noValidate onSubmit={handleSubmit}>
					<TextField
						error={Boolean(touched.email && errors.email)}
						fullWidth
						margin="normal"
						autoFocus
						helperText={touched.email && errors.email}
						label={t('Email')}
						name="email"
						onBlur={handleBlur}
						onChange={handleChange}
						type="email"
						value={values.email}
						variant="outlined"
					/>
					<TextField
						error={Boolean(touched.password && errors.password)}
						fullWidth
						margin="normal"
						helperText={touched.password && errors.password}
						label={t('Heslo')}
						name="password"
						onBlur={handleBlur}
						onChange={handleChange}
						type="password"
						value={values.password}
						variant="outlined"
					/>
					<Box alignItems="center" display={{ xs: 'block', md: 'flex' }} justifyContent="space-between">
						<Link component={RouterLink} to="/account/recover-password">
							<b>{t('Zabudli ste heslo?')}</b>
						</Link>
					</Box>

					{Boolean(touched.terms && errors.terms) && <FormHelperText error>{errors.terms}</FormHelperText>}

					<Button
						sx={{
							mt: 3
						}}
						color="primary"
						startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
						disabled={isSubmitting}
						type="submit"
						fullWidth
						size="large"
						variant="contained"
					>
						{t('Prihlásiť')}
					</Button>
				</form>
			)}
		</Formik>
	);
};

export default LoginJWT;
