import { useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import { Avatar, Box, Button, Divider, List, ListItem, ListItemText, Popover, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import { getFileUrl } from 'src/functions';
import _ from 'lodash';

const UserBoxButton = styled(Button)(
	({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(0)};
        color: ${theme.colors.alpha.trueWhite[70]};

        &:hover {
          color: ${theme.colors.alpha.trueWhite[100]};
        }
`
);

const MenuUserBox = styled(Box)(
	({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
	({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
	({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
	({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function HeaderUserbox() {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const { user, logout } = useAuth();

	const ref = useRef(null);
	const [isOpen, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleLogout = async () => {
		try {
			handleClose();
			await logout();
			navigate('/');
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Box
			component="span"
			sx={{
				display: { xs: 'none', sm: 'inline-block' }
			}}
		>
			<UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
				<Avatar alt={user.name} src={!_.isEmpty(user.avatar) ? getFileUrl(user.avatar) : ''} />
				<ExpandMoreTwoToneIcon
					fontSize="small"
					sx={{
						ml: 0.5
					}}
				/>
			</UserBoxButton>
			<Popover
				disableScrollLock
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<MenuUserBox
					sx={{
						minWidth: 210
					}}
					display="flex"
				>
					<Avatar variant="rounded" alt={user.name} src={!_.isEmpty(user.avatar) ? getFileUrl(user.avatar) : ''} />
					<UserBoxText>
						<UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
						<UserBoxDescription variant="body2">{`${user.type === 'admin' ? t('Admin') : t('Člen')}${user.is_player ? ' / ' + t('Hráč') : ''}${
							user.is_coach ? ' / ' + t('Tréner') : ''
						}`}</UserBoxDescription>
					</UserBoxText>
				</MenuUserBox>
				<Divider
					sx={{
						mb: 0
					}}
				/>
				{user.is_player || user.is_coach ? (
					<List
						sx={{
							p: 1
						}}
						component="nav"
					>
						<ListItem
							button
							onClick={() => {
								handleClose();
							}}
							to={`/app/${user.is_player ? 'players' : 'coaches'}/list/edit/${user.is_player ? user.player_id : user.coach_id}`}
							component={NavLink}
						>
							<SettingsIcon fontSize="small" />
							<ListItemText primary={t('Nastavenia')} />
						</ListItem>
					</List>
				) : null}
				{user.is_player || user.is_coach ? <Divider /> : null}
				<Box m={1}>
					<Button color="primary" fullWidth onClick={handleLogout}>
						<LockOpenTwoToneIcon
							sx={{
								mr: 1
							}}
						/>
						{t('Odhlásenie')}
					</Button>
				</Box>
			</Popover>
		</Box>
	);
}

export default HeaderUserbox;
